<template>
  <div>
    <div class="default-opt">
      <div
        class="item"
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: index == selectedIndex }"
        @click="selectTab(index)"
      >
        <div class="check">
          <img src="@/assets/images/ck_research.svg" />
        </div>
        <p class="title">{{ tab.tabData.title }}</p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      selectedIndex: 0,
      currentProgressNum: 1,
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
    //console.log("정보", this.tabs);
  },
  mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      this.currentProgressNum = i + 1;
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
      /** 선택사항 이벤트 수신시 리로드 */
      this.$emit("reloadRoomList", i);
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/css/common.css";
.app-context {
  .note-list {
    .default-opt {
       display: flex;
      position: relative;
      margin: 0;
       display: flex;
      position: relative;
      margin: 0;
      &.noneEffect {
        .item {
        &:after {
          display: none;
        }
        }
      }
&:after {
          content: "";
    height: 4px;
    width: 100%;
    background: #146f83;
    position: absolute;
    bottom: 0;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190px;
        height: 55px;
        border-top: 1px solid #707070;
        border-right: 1px solid #707070;
        border-bottom: 4px solid #146f83;
        border-left: 1px solid #707070;
        background: #fafafa;
        position: relative;
        cursor: pointer;
        .check {
          margin: 0 10px 0 0;
          display: none;
          position: absolute;
          left: 40px;
          img {
          }
        }
        p.title {
          font-family: SUIT;
          font-size: 21px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #b7b7b7;
          line-height: 29px;
          padding: 0;
        }
        &:nth-of-type(1) {
        }
        &:nth-of-type(2) {
          border-left: 0px solid #707070;
        }

        &.active {
          border-bottom: 0px;
          &:after {
               width: 100%;
                content: "";
                height: 4px;
                background: #fff;
                position: absolute;
                bottom: 0;
                z-index: 1;
          }
          background: #fff;
          .check {
            display: block;
          }
          p.title {
            color: #146f83;
            padding: 0;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
